import { NhostClient } from '@nhost/vue'

import { createApolloClient } from '@nhost/apollo'
import { createApolloProvider } from '@vue/apollo-option'

const nhost = new NhostClient({
  graphqlUrl: `${import.meta.env.VITE_API_GRAPQHQL_URL}/v1/graphql`,
  authUrl: `${import.meta.env.VITE_API_GRAPQHQL_URL}/v1/auth`,
  storageUrl: `${import.meta.env.VITE_API_STORAGE_URL}/v1`,
  functionsUrl: "functions",
})
export const apolloClient = createApolloClient({ nhost, headers: { 'x-hasura-role': 'user' } })

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

// ? Make it part of @nhost/apollo?
nhost.auth.onAuthStateChanged((d) => {
  if (d === 'SIGNED_OUT') {
    apolloClient.clearStore()
  }
})

export default nhost
